<p>chat-inbox works!</p>
<div class="chat-messages">
    <div class="chat-messages-show-container">
      <ul class="chat-messages-show-list" id="message-list">
      </ul>
    </div>
    <div class="chat-messages-create-container"> 
      <input class="chat-messages-create-input" type="text" [(ngModel)]="message" (keyup.enter)="SendMessage()">
      <button  class="chat-messages-create-button" (click)="SendMessage()"> Send </button>
    </div>
 </div>